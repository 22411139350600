import { useEffect, useState } from 'react';
import { getSnapshot, applySnapshot, onPatch } from 'mobx-state-tree';

const useEditor = (model, saveCallback = false) => {
  const [dirty, setDirty] = useState(false);
  const [initial, setInitial] = useState(getSnapshot(model));

  useEffect(() => {
    // const disposer = addMiddleware(model, (call, next) => {
    //   if (
    //     call.type === 'action' &&
    //     !['@APPLY_SNAPSHOT', 'save', 'load', 'update'].includes(call.name)
    //   ) {
    //     setDirty(true);
    //   }
    //   next(call);
    // });

    const disposer = onPatch(model, () => {
      setDirty(model.isReady());
    });

    // TODO: This is no longer true, make sure current implementation works though...
    if (model.state === undefined) {
      // eslint-disable-next-line no-console
      console.warn('Model has no state, cannot use with useEditor hook');
    } else if (model.isReady()) {
      setInitial(getSnapshot(model));
    } else {
      model.load();
    }

    setDirty(false);

    return () => {
      disposer();
    };
  }, [model.id, model.state]);

  const _editor = {
    save: () => {
      (saveCallback || model.save)().then(() => {
        setInitial(getSnapshot(model));
        setDirty(false);
      });
    },
    cancel: () => {
      applySnapshot(model, initial);
      setDirty(false);
    },
    dirty
  };

  return _editor;
};

export default useEditor;
